$(document).ready(function(){
    $('#phoneNumber').inputmask({"mask": "999 999 9999"});
    $('#altPhoneNumber').inputmask({"mask": "999 999 9999"});
    $('#physPhone').inputmask({"mask": "999 999 9999"});
    $('#physFax').inputmask({"mask": "999 999 9999"});
    $('#patientPhone').inputmask({"mask": "999 999 9999"});
    $('#patientFax').inputmask({"mask": "999 999 9999"});
    $('#postalCode').inputmask('A9A 9A9');
    $('#altPostalCode').inputmask('A9A');
    $('#physPCode').inputmask('A9A 9A9');
    $('#patientPCode').inputmask('A9A 9A9');
    $('#dateOfBirth').inputmask("y-m-d", {"placeholder": "yyyy-mm-dd" });
    $('#clientId').inputmask('AAAAAAAAAA999AA');
    $('#cvv').inputmask('999');
    $('#din').inputmask('99999999');

    $('#advocatePhoneNumber').inputmask({"mask": "999 999 9999"});
    $('#advocatePostalCode').inputmask('A9A 9A9');

    $('#physProv').inputmask('AA');
    $('#patientProv').inputmask('AA');

    $('[data-toggle="tooltip"]').tooltip();
    $('#subjectNumber').inputmask('999999');

});
